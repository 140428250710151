import { translate, subscribe } from "../b2b-i18n";
import { Translation } from "../types";

export default {
  mounted() {
    subscribe(() => {
      //@ts-expect-error: Missing Vue typing
      this.reloadI18n();
    });
  },
  methods: {
    t(key: string, data?: Record<string, string | number | Translation>): string {
      return translate(key, data);
    },
    reloadI18n(): void {
      //@ts-expect-error: Missing Vue typing
      this.$forceUpdate();
    },
  },
};
