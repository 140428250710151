import { NestedObject, Translations } from "./types";

export const flatten = (
  obj: Translations | NestedObject,
  prefix?: string
): Record<string, string> =>
  Object.entries(obj).reduce(
    (acc, [key, val]) =>
      typeof val === "string"
        ? { ...acc, [getKey(key, prefix)]: val }
        : { ...acc, ...flatten(val, getKey(key, prefix)) },
    {}
  );

const getKey = (key: string, prefix?: string): string =>
  (prefix ? `${prefix}__${key}` : key).replace(/\./g, "__");
