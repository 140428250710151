import { isEnabled as featureToggleIsEnabled } from "@telia/b2b-feature-toggles";

enum Feature {
  INSTANT_I18N = "instant-i18n",
}

const toggles: Record<Feature, boolean> = {
  [Feature.INSTANT_I18N]: false,
};

export const instantI18nEnabled = (): boolean => isEnabled(Feature.INSTANT_I18N);

function isEnabled(toggleName: Feature): boolean {
  return featureToggleIsEnabled(toggleName, toggles);
}
